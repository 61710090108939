@import '../../../../../assets/styles/mixins.scss';

.risk-assessment-info-wrapper {
    padding: 20px;
    background-color: var(--primary-white-color);
    margin: 20px;
    .heading {
        color: var(--font-black);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.38px;
    }

    .risk-assessment-form{
        display: flex;
        justify-content: space-between;

        >div {
            flex: 1;
        }

        >div:not(:first-child) {
            margin-left: 10px;
        }
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;
        width: 10%;
        margin-left: auto;

        button {
            border-radius: 5px;
        }
    }

}