@import '../../../assets/styles/mixins.scss';

.core-wrapper {
    width: 100%;
    // background-color: var(--surface-grey-color);
    height: 100%;

    .card-wrapper {
        background-color: var(--primary-white-color);
        width: 98%;
        margin: auto;
        height: 95%;
        padding: 25px;
    }
}

.header {
    display: flex;
    justify-content: space-between;

    .heading {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: -0.022em;
        color: var(--font-black);

    }

    .action {
        display: flex;

        @include for-size($global-phone-only) {
            flex-wrap: wrap;
        }

        button {
            white-space: nowrap;
            margin-left: 30px;
            border: none;
            border-radius: 10px;
            height: 38px;
        }
    }
}

.articles-card {
    height: 95%;
    overflow-y: auto;
    margin-top: 10px;
}

.articles-card .ra-card:not(:last-child) {
    margin-bottom: 15px;
}

.articles-card .ra-card:first-child {
    margin-top: 15px;
}

// article card

.article-wrapper {
    height: inherit;
    width: 100%;
    position: relative;

    .doc-type {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: 5px;

        span {
            margin-top: 4px;
            background-color: red;
            width: 28px;
            height: 28px;
            justify-content: center;
            display: flex;
            margin-right: 10px;
            border-radius: 24px;
        }

        img {
            transform: scale(0.6);
        }
    }

    .article {
        display: flex;
        padding: 10px;
        height: 90%;
        justify-content: space-between;

        .thumbnail {
            border: 1px solid darkgray;
        }

        .details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            margin-left: 12px;

            .model-number {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.019em;
                color: var(--font-black-variant1);
            }

            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 150%;
                letter-spacing: -0.019em;
                color: var(--font-black-variant1);
            }

            .type {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 4px 16px;
                gap: 10px;
                width: fit-content;
                height: 26px;
                background: #E4E4E4;
                font-size: 12px;
                border-radius: 33px;
                margin-top: 8px;
            }
        }
    }
}

.dates {
    display: flex;

    .effective,
    .expiry {
        span {
            display: block;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.019em;
            color: var(--font-black-variant1);
            margin-right: 12px;
        }

        .date {
            font-size: 16px;
        }

        .date-title {
            font-size: 12px;
        }
    }
}

.view-option {
    display: flex;
    justify-content: flex-end;

    span {
        background-color: var(--primary-blue-color);
        border-radius: 12px;
        padding: 3px 12px;
        color: var(--primary-white-color);
        cursor: pointer;

        img {
            margin-right: 4px;
        }
    }
}