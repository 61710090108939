$global-phone-only: 'phone-only';
$global-tablet-portrait-up: 'tablet-portrait-up';
$global-tablet-landscape-up: 'tablet-landscape-up';
$global-desktop-up: 'desktop-up';
$global-big-desktop-up: 'big-desktop-up';

:root {
    // color variables 
    --primary-blue-color: #000099;
    --primary-white-color: #FFFFFF;
    --surface-grey-color: #EBEBEB;
    --light-grey-color: #F8F9FC;
    --background-grey: #6C6C6C;
    --light-gray-background: #EEE;
    --light-blue: #F1F3F9;
    --font-black: #000000;
    --font-black-2: #1E1E1E;
    --font-black-variant1: #1E1E1E;
    --font-gray:rgba(0, 0, 0, 0.54);
    --font-gray1: #AFAFAF;
    --font-gray2: #cdcdcd;
    --font-gray3: #dcdbdb;
    --light-gray: #EDEEF1;
    --light-gray-4: #EDEDED;
    --font-dark: #2D1D45;
    --status-restricted: rgba(185, 28, 28, 0.10);
    --status-normal: rgba(0, 0, 153, 0.10);
    --status-unrestricted: rgba(214, 237, 223, 0.10);

}