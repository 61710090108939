@import '../../../../../assets/styles/mixins.scss';

.specific-risk-assessment-wrapper {
    margin: 20px;
    height: 90%;
    display: flex;
    flex-direction: column;

    .progress-header {
        background-color: var(--font-gray3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        padding: 8px 12px;
        text-align: right;
        font-family: Roboto;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.38px;
    }

    .pl-search {
        margin-top: 10px;
        display: flex;
        .sorting{
            margin-left: auto;
            cursor: pointer;
        }
        .filter{
            color: #1E1E1E;
            text-align: right;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 150%; /* 27px */
            letter-spacing: -0.342px;
            cursor: pointer;
            margin: 20px;
        }
    }

    article {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: white;
        padding: 15px;
    }

    main {
        flex: 1;
        background-color: white;
        overflow-y: auto;
    }

    .assessment-details {
        .ra-actions {
            display: flex;
            justify-content: end;
            align-items: center;
            height: 100%;

            button {
                width: 25%;
                margin-left: 10px;
            }

            .export-btn {
                width: 90%;
                display: flex;
                justify-content: center;
                height: 50px;
                background: #D6EDDF;
                align-items: center;
                border: 1px solid black;
                border-radius: 5px;
                margin-right: 100px;
            }
        }

        .details {
            color: var(--font-black-variant1);
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.456px;

            .heading {
                font-size: 1.5rem;
                margin-bottom: 0.6rem;
            }

            .pl-list,
            .pm-list {
                font-size: 1rem;
                margin-bottom: 0.3rem;
            }
        }
    }
}