.loader-wrapper {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #2a2828;
    opacity: 0.5;
    z-index: 1064;
}
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 