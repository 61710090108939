@import '../../assets//styles/mixins.scss';

.wrapper {
    @include splash-card;
    min-width: 400px;

    @include for-size($global-phone-only) {
        min-width: 340px;
    }

    .logo {
        height: 125px;
        width: 244px;
    }

    .forgot-password {
        width: 100%;
        text-align: center;
        padding: 0px 20px 20px 20px;

        .heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: -0.15px;
            color: var(--font-black);
        }

        .quote {
            margin-bottom: 10px;
            text-align: left;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.15px;
            color: var(--font-black);
            padding: 12px 0;
        }

        .verification-link-resend {
            text-align: left;
            margin-top: 15px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.15px;
            color: #000000;

            .link-resend {
                @include link()
            }
        }

        .success-icon {
            text-align: left;
            margin: 14px 0;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.1px;
            color: #000000;
        }
    }
}