@import '../../../../../../assets/styles/mixins.scss';

.internal-notes-wrapper {
    .header {
        .label {
           
            flex-grow: 1;
            color: #1A1D1E;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            /* 133.333% */
        }

        img {
            scale: 1;
            cursor: pointer;
        }
    }

    .internal-notes-form {
        .title {
            color: black;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 24px;
            margin-bottom: 20px;

        }
    }


}