@import '../../../../../assets/styles/mixins.scss';

.assumptions-wrapper {
    padding: 20px;
    background-color: var(--primary-white-color);
    margin: 20px;
    height: 90%;
    overflow: scroll;

    .heading {
        color: var(--font-black);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.38px;
    }

    .item-wrapper {

        .action-button-wrapper {
            display: flex;
            justify-content: space-between;


            .label {
                color: #000;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                flex: 1
                    /* 111.111% */
            }

            button {
                width: 150px;
            }
        }

        .assumption-text {
            border-radius: 4px;
            border: 1px solid var(--Gray-23, rgba(0, 0, 0, 0.23));
            background: #FFF;
            padding: 20px 10px 20px 10px;
            margin: 12px 0px 40px 0px;
            color: var(--Gray-87, rgba(0, 0, 0, 0.87));
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            white-space: pre-line;
            overflow:auto;
            word-break: break-word;
            min-height: 100px;
            // max-height: 500px;
            // overflow: scroll;
            
            /* 125% */

        }
    }

}

.add-edit-assumptions {

    .modal-content {
        padding: 16px !important;
    }

    .header {
        .label {
            flex: 1;
            text-align: center;
        }
    }

    .action-btns {
        display: flex;
        justify-content: center;

        button {
            width: 30%;
            margin:0 10px;
        }
    }
}