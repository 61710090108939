@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@font-face {
    font-family: 'Inter';
    src: local('Inter'), url('../fonts/Inter-VariableFont_slnt\,wght.ttf') format('woff');
  }
.toast-fail {
    background-color: #F9ECEC!important;
    border: 2px solid #C74244;
    border-left: 7px solid #C74244;
    color: black !important;
    font-weight: 500;
}
.toast-success {
    background-color: #EDF6EE !important;
    border: 2px solid #4AA155;
    border-left: 7px solid #4AA155;
    color: black !important;
    font-weight: 500;
}
