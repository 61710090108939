@import '../../../../../assets/styles/mixins.scss';

.new-risk-assessment {
    .header {
        img {
            scale: 1.2;
            cursor: pointer;
        }
    }

    .risk-assess-row {
        >div {
            flex: 1;
        }
        .ra-access {
            margin-bottom: 1.5rem;
            margin-left: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--font-gray3);
            padding: 7px;
            border-radius: 5px;
            text-transform: capitalize;
            cursor: pointer;
            img {
                scale: 0.7;
            }
        }
        
    }

    .label {
        color: #1A1D1E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex-grow: 1;
    }

    fieldset {
        width: 100%;
        margin-bottom: 10px;

        legend {
            text-align: left;
            margin: 0;
        }

        div[role="radiogroup"] {
            display: flex;
            flex-direction: row;
        }
    }

    .user-multiselect {
        margin-bottom: 1.5rem;
    }
    .action-buttons {
        display: flex;
        width: 50%;
        margin: auto;
        button {
            margin: 0 10px;
            border-radius: 5px;
        }
    }
}