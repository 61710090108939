@import '../../assets/styles/mixins.scss';

.pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.6px;

    .row-options {
        display: flex;
        text-align: center;
        align-items: center;
        margin: 0 10px;
        .label {
            margin-top: -3px;
        }

        .dropdown {
            button {
                background: transparent;
                color: black;
                border: none;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
            }
        }
    }

    .page-info {
        margin: 0 10px;
    }

    .nav-actions {
        margin: 0 10px;
        img {
            margin: 0 20px;
            cursor: pointer;
        }
        .disable-nav {
            pointer-events: none;
            filter: invert(1);
        }
    }
}