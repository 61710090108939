@import '../../assets/styles/mixins.scss';

.feedback-wrapper{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 20px;

    .header {
        display: flex;
        justify-content: space-between;

        .heading {
            color: var(--font-black);
            font-family: Roboto;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem;

        }
    }

    .feedback-form{
        text-align: center;
        padding: 0px 60px 20px 20px;
        
        .action-button{
            display: flex;
            width: 10%;
            margin: auto;
        }
    }

    .pl-search {
        display: flex;
        justify-content: flex-end;
        img{
            margin-right: 1.5rem;
        }
    }

    .user-actions {
        img {
            margin: 0 15px;
            cursor: pointer;
        }
    }
    .pagination {
        display: flex;
        justify-content: end;
    }

}

.view-feedback {

    .header {
        img {
            scale: 1.2;
            cursor: pointer;
        }
    }

    .label {
        color: #1A1D1E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex-grow: 1;
    }

    .action-buttons {
        display: flex;
        width: 30%;
        margin: auto;
        button {
            margin: 0 10px;
            border-radius: 5px;
        }
    }

}

.reply-feedback{

    .header {
        img {
            scale: 1.2;
            cursor: pointer;
        }
    }

    .label {
        color: #1A1D1E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex-grow: 1;
    }

    .action-buttons {
        display: flex;
        width: 70%;
        margin: auto;
        button {
            margin: 0 10px;
            border-radius: 5px;
        }
    }
}
