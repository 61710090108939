@import '../../assets/styles/mixins.scss';

.confirm-cancel-box {
    .close-icon {
        display: flex;
        justify-content: end;
    }

    .main-content {
        padding: 1rem;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .header {
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.1px;

        img {
            scale: 1.2;
            cursor: pointer;
        }
    }

    .type-delete-wrapper {
        .help-text {
            text-align: center;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
        input {
            margin-top: 1.5rem;
            height: 48px;
            width: 100%;
            padding: 0 10px;
        }
    }

    .action-btns {
        display: flex;
        width: 75%;
        margin-top: 20px;
        button{
            margin: 1rem;
        }
    }
}