@import '../../../assets/styles/mixins.scss';

.machine-card {
    .ra-card {
        margin-bottom: 10px
    }

    ;
}

.main-section-wrapper {
    background-color: var(--light-gray-background);
    height: 100%;

    nav {
        padding: 1rem 1.5rem 0 1.5rem;

        a {
            color: var(--primary-blue-color);
            cursor: pointer;
        }

        .disable-click-current {
            color: black;
            font-size: 1.25rem;
            font-weight: 600;
            pointer-events: none;
        }
    }

    .MuiBreadcrumbs-ol {
        li {
            max-width: 225px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}