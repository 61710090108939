@import '../../../../../assets/styles/mixins.scss';

.product-models-wrapper {
    margin: 20px;

    .product-model-header {
        background-color: var(--font-gray3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;

        .product-model-heading {
            display: flex;
            align-items: center;
            img {
                // margin: 0 15px;
                height: 80px;
                width: 100px;
            }
            span {
                color:  rgba(0, 0, 0, 0.87);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                margin: 0 15px;
            }
        }
        .product-modal-actions {
            display: flex;
            flex: 0.6;
            justify-content: flex-end;
            button {
                margin-left: 10px;
                max-width: 200px;
            }
        }
    }
    .product-model-list-wrapper {
        height: 70vh;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        width: 81vw;
        justify-content: flex-start;
    }
}