@import '../../../../../assets/styles/mixins.scss';

.revision-history-wrapper {
    padding: 20px;
    background-color: var(--primary-white-color);
    margin: 20px;

    .heading {
        color: var(--font-black);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.38px;
    }
    button {
        width: 120px;
        height: 40px;
    }

    .revision-history-table {

        thead th {
            background-color: black;
            color: white;
        }
    }
}

.add-edit-revision-history {

    .modal-content {
        padding: 16px !important;
    }

    .header {
        .label {
            flex: 1;
            text-align: center;
        }
    }

    .action-btns {
        display: flex;
        justify-content: center;

        button {
            width: 30%;
            margin:0 10px;
        }
    }
}