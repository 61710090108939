@import '../../../../../assets/styles/mixins.scss';

.import-event{
    .header {
        img {
            scale: 1.2;
            cursor: pointer;
        }
    }

    .label {
        color: #1A1D1E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex-grow: 1;
    }

    .user-multiselect {
        margin-bottom: 1.5rem;
    }

    .action-buttons {
        display: flex;
        width: 50%;
        margin: auto;
        button {
            margin: 0 10px;
            border-radius: 5px;
        }
    }
}