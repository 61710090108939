@import '../../../../../assets/styles/mixins.scss';

.share-risk-assessment {
    .header {
        img {
            scale: 1.2;
            cursor: pointer;
        }

        p {
            margin: auto;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }

    .link-copied {
        text-align: right;
        color: rgb(2, 85, 2);
        font-weight: 600;
        letter-spacing: normal;
    }

    .input-box {
        position: relative;
        height: 48px;
        flex: 1;
        display: flex;

        label {
            z-index: 1;
            position: absolute;
            top: -13px;
            left: 22px;
            background-color: white;
        }

        .box {
            width: 100%;
            position: relative;
            height: inherit;
            border: 1px solid #0000003B;
            text-align: left;
            padding: 10px 10px;

            a{
                text-decoration: none;
                color: var(--primary-blue-color);
            }

            .copy-image {
                position: absolute;
                right: 10px;
                top: 15px;
                cursor: pointer;
            }
        }
    }

    .copy-button {
        button {
            width: 20%;
            margin-top: 20px;
        }
    }
}