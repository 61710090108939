@import '../../../../../assets/styles/mixins.scss';

.product-line-card-wrapper {
    width: 280px;
    text-align: center;
    padding: 15px;
    border-radius: 8px;
    background: #F8F8F8;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 15px;
    max-height: 400px;

    .product-line-card-inner {
        display: flex;
        justify-content: space-between;
        height: 100%;

        .more-action-button {
            cursor: pointer;
            display: flex;
            justify-content: end;
            margin-bottom: 10px;

            .more-options {
                span {
                    margin: 0 5px;
                    font-family: Inter;
                }

                .edit {
                    img {
                        scale: 0.8;
                    }
                }
            }
        }

        div:nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            justify-content: center;
        }
    }

    .pl-name {
        margin-bottom: 12px;
        @include bold-dark-heading(20px, 600)
    }

    .pl-image {
        width: 200px;
            height: 172px;
            justify-content: center;
            align-items: center;
            display: flex;
        img {
            // width: 10px;
            max-width: 200px;
            max-height: 172px;
        }
    }

    .pl-model-add,
    .pl-model-view {
        margin-bottom: 12px;
    }
}

// .product-line-card-height {
//     height: 250px;
// }