@import '../../assets/styles/mixins.scss';

.upload-container-wrapper {
    width: 100%;
    top: 0;
    position: absolute;
    display: flex;
    flex-flow: column;
    height: 100%;

    nav {
        position: absolute;
        top: 0;
        width: 100%;
    }
}



.status {
    display: flex;
    flex-flow: column;
    height: auto;
    width: auto;
    justify-content: center;
    align-items: center;
    padding: 30px;

    img {
        height: 48px;
        width: 48px;
    }

    span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: var(--font-dark);
    }
}

.uploading {
    border: 4px solid var(--light-gray);
    border-radius: 50%;
    height: 150px;
    width: 150px;
    padding: 0;
}

.help-text {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: var(--font-dark);
}

.browse {
    width: 100%;

    button {
        margin: 10px 0;
    }

    input[type="file"] {
        display: none;
    }

    .custom-file-upload {
        width: 100%;
        text-align: center;
        padding: 10px;
        background-color: var(--primary-blue-color);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.15px;
        color: var(--primary-white-color);
    }
}

.progress-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: flex-start;
}


.single-upload {
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    // border-bottom: 3px solid lightgray;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: var(--font-black);

    span {
        margin-right: 8px;
    }
}

.green-border {
    border-bottom: 3px solid green;
}

.blue-border {
    border-bottom: 3px solid blue;
}

.red-border {
    border-bottom: 3px solid red;
}

.upload-progress-bar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 60px;
    }

    .position-image {
        top: 26px;
        right: 46px;
        position: absolute;
    }

    .position-status {
        right: 41px;
        top: 90px;
        position: absolute;
    }

    svg {
        height: 150px;
        width: 150px;
    }

}

.base {
    fill: none;
    stroke: #b6b6b6;
    stroke-width: 5px;
    opacity: 0.6;
}

.progress {
    fill: none;
    stroke: var(--primary-blue-color);
    stroke-width: 5px;

    &.animated {
        transition: stroke-dashoffset 2s;
    }
}

.pointerContainer {
    &.animated {
        transition: transform 2s;
    }
}