@import '../../.././../../assets/styles/mixins.scss';

.ra-nested-tabs-wrapper {
    display: flex;
    justify-content: space-between;
    .nested-tab {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 0.9rem;
        background: var(--light-gray-4);
        padding: 10px;
        flex: auto;
        cursor: pointer;
        &:hover{
            font-weight: 600;
            scale: 1.01;
        }
    }
    .nested-tab:not(:last-child){
        margin-right: 10px;
    }
}