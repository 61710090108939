@import '../../assets//styles/mixins.scss';

.navbar {
    background-color: var(--primary-white-color);
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 10px 24px;

    .logo {
        img {
            @include for-size($global-phone-only) {
                height: 50px;
                width: 150px;
            }
        }
    }

    .buttons {
        display: flex;
        align-self: center;

        button {
            white-space: nowrap;
            margin-left: 10px;
            border-radius: 5px;
             padding: 0 15px;
        }

        button:first-child {
            border: 2px solid var(--primary-blue-color);
        }
    }

    .user-profile {
        display: flex;

        div:first-child {
            display: flex;
            flex-direction: column;

            span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 125%;
                text-align: right;
            }

            span:first-child {
                color: var(--font-black);
                text-transform: capitalize;
                color: #252424;
                text-align: right;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 1000
            }

            span:nth-child(2) {
                color: var(--font-grey);
                color: var(--background-grey);
                text-align: center;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
            }
        }

        div:nth-child(2) {
            margin-left: 15px;
        }
    }
}