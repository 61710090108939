.footer-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--light-gray);
    padding: 6px;

    .footer-content {
        list-style: none;
        display: flex;
        justify-content: center;
        margin: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--font-black);
        padding: 0;

        a{
            color: black;
            text-decoration: none;
        }
    }

    li {
        margin: 0 10px;
        cursor: pointer;
        &:hover {
            font-weight: bold;
        }
    } 
}